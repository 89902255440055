<template>
	<div class="wrap">
		<div class="content">
			<ByIcon icon="oops" size="300"></ByIcon>
			<h2>404</h2>
			<p>找不到此頁面</p>
		</div>
		<ByFooter />
	</div>
</template>

<script>
export default {
	name: 'NotFound',
}
</script>

<style lang="sass" scoped>
.wrap
  +fc
  width: 100%
  min-height: 100%
  flex-direction: column
  transform: translateY(-25px)
.content
  flex: 1
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column
  padding: 25px 0
h2
  margin: 10px 0 15px 0
  font-size: 32px
p
  line-height: 1.5
  font-size: 16px
</style>
